import React from 'react';
import ContactForm from './ContactForm';
// import { scrollDisable, scrollEnable } from '../utils/helpers';
import CloseIcon from '../images/ico-close.svg';
import '../css/_modals.scss';

export default function ContactModal() {
  return (
    <>
      <div className="contact-modal">
        <div className="modal-content">
          <ContactForm
            heading="Book a Consultation"
            p="Fill out the form and we will contact you as soon as possible to arrange a consultation."
          />
          <div className="close">
            <img src={CloseIcon} alt="x" />
          </div>
        </div>
      </div>
    </>
  );
}
