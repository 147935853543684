import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export default function SEO({ location, title, description, custom }) {
  const query = graphql`
    {
      site {
        siteMetadata {
          siteTitle: title
          siteDesc: description
          siteUrl
          image
        }
      }
    }
  `;

  const { site } = useStaticQuery(query);
  const { siteTitle, siteDesc, image } = site.siteMetadata;

  return (
    <Helmet title={custom ? `${title} | ${custom}` : `${title} | ${siteTitle}`}>
      <html lang="en" />
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image} />
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link rel="alternate icon" href="favicon.png" type="image/x-icon" />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || '/favicon.svg'} />
      <meta property="og:title" content={siteTitle} key="ogtitle" />
      <meta property="og:site_name" content={title} key="ogsitename" />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
      <meta name="msapplication-TileColor" content="#FEDA31" />
      <meta name="theme-color" content="#FEDA31" />
    </Helmet>
  );
}
