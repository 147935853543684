import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { globalHistory } from '@reach/router';
import gsap from 'gsap';
import { scrollNav, scrollEnable, scrollDisable } from '../utils/helpers';

import Logo from '../images/logo-gradient.svg';

export default function Navbar({ transparentBg }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // Determin if our menu-btn should be disabled
  const disabledMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  function handleMenu() {
    disabledMenu();
    setMenuOpen(!menuOpen);

    if (!menuOpen) {
      /* eslint-disable */
      const tl = gsap.timeline();
      tl.to('.menu-bg', {
        duration: 0.6,
        // y: '100%',
        height: '100vh',
        ease: 'power3.inOut',
        stagger: 0.2,
      })
      tl.from('.menu-items li', {
        duration: 0.8,
        y: 30,
        opacity: 0,
        ease: 'power3.out',
        stagger: 0.1,
      }, '-=0.4');

      scrollDisable(); //don't scroll when menu open

    } else {
      const tl = gsap.timeline();
      tl.to(['.menu-bg-2', '.menu-bg-1'], {
        duration: 0.4,
        // y: 0,
        height: 0,
        ease: 'power3.inOut',
        stagger: 0.15,
      })

      scrollEnable();
      /* eslint-enable */
    }
  }

  useEffect(() => {
    // Close menu when page changes
    if (menuOpen === true) {
      globalHistory.listen(() => {
        handleMenu();
      });
    }

    // Scroll navigation
    scrollNav();

    // Capture scroll position
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`
      );
    });
  });

  const data1 = useStaticQuery(graphql`
    {
      allSanitySector(
        # filter: { residential: { eq: true } }
        sort: { fields: order }
      ) {
        nodes {
          residential
          title
          slug {
            current
          }
          short
          icon {
            asset {
              url
            }
          }
        }
      }
    }
  `);
  const allMenu = data1.allSanitySector.nodes;
  const residentialMenu = allMenu.filter((menu) => menu.residential === true);
  const commercialMenu = allMenu.filter((menu) => menu.residential === false);

  return (
    <header>
      <nav className={`navbar ${transparentBg ? 'transparent-bg' : ''}`}>
        <div className="wrapper">
          <Link to="/" className="logo">
            <img src={Logo} alt="Connect logo" />
          </Link>

          {/* Desktop menu */}
          <ul className="main-menu">
            <li>
              <Link to="/services" className="navlink">
                Services
              </Link>
            </li>
            <li className="dropdown">
              <span className="menu-name">Residential</span>

              <div className="dropdown-menu">
                {residentialMenu.map((menu, i) => (
                  <Link to={`/${menu.slug.current}`} key={i}>
                    <h6>{menu.title}</h6>
                    {/* <p>{menu.short}</p> */}
                  </Link>
                ))}
              </div>
            </li>
            <li className="dropdown">
              <span className="menu-name">Commercial</span>

              <div className="dropdown-menu">
                {commercialMenu.map((menu, i) => (
                  <Link to={`/${menu.slug.current}`} key={i}>
                    <h6>{menu.title}</h6>
                    {/* <p>{menu.short}</p> */}
                  </Link>
                ))}
              </div>
            </li>
            <li>
              <Link to="/products" className="navlink">
                Products
              </Link>
            </li>
            <li>
              <Link to="/about" className="navlink">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="button small">
                Contact us
              </Link>
            </li>
          </ul>

          {/* Mobile menu */}
          <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
            <button
              className="menu-btn"
              onClick={handleMenu}
              disabled={disabled}
              type="button"
              aria-label="menu"
            >
              <span />
              <span />
            </button>

            <div className="menu-bg menu-bg-1" />
            <div className="menu-bg menu-bg-2" />

            <div className="menu-items">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Our services</Link>
                </li>
                <li>
                  <span className="category">Residential</span>
                  <div className="links">
                    {residentialMenu.map((menu, i) => (
                      <Link to={`/${menu.slug.current}`} key={i}>
                        {menu.title}
                      </Link>
                    ))}
                  </div>
                </li>
                <li>
                  <span className="category">Commercial</span>
                  <div className="links">
                    {commercialMenu.map((menu, i) => (
                      <Link to={`/${menu.slug.current}`} key={i}>
                        {menu.title}
                      </Link>
                    ))}
                  </div>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
