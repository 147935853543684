import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Logo from '../images/logo-vertical.svg';
import Fb from '../images/ico-fb.svg';
import Tw from '../images/ico-tw.svg';
import Ig from '../images/ico-ig.svg';
import Li from '../images/ico-li.svg';

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteInfo {
        contact {
          address
          email
          email2
          phone
        }
        footerCTA
        partnerLogos {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
      allSanitySector(sort: { fields: order }) {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `);

  const { contact } = data.sanitySiteInfo;
  const { footerCTA } = data.sanitySiteInfo;
  const partners = data.sanitySiteInfo.partnerLogos;
  const ph1 = contact.phone.slice(0, 2);
  const ph2 = contact.phone.slice(2, 5);
  const ph3 = contact.phone.slice(5, 8);
  const ph4 = contact.phone.slice(8, contact.phone.length);
  const fullPhone = `${ph1} (${ph2}) ${ph3} - ${ph4}`;
  const sectors = data.allSanitySector.nodes;

  return (
    <footer>
      <div className="partners">
        <div className="wrapper">
          {/* <p>Trusted partners</p> */}
          <div className="logos">
            {partners.map((logo, i) => (
              <GatsbyImage
                image={logo.asset.gatsbyImageData}
                alt={logo.alt}
                className="logo"
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="main-footer">
        <div className="wrapper">
          <div className="cta-section">
            <div className="content">
              <h3>{footerCTA}</h3>
              <button className="open-contact-modal" type="button">
                Book a free consultation
              </button>
              <div className="call-us">
                <p>Want to chat about our services? Call us at</p>
                <a href={`tel:${contact.phone}`} className="underline white">
                  {fullPhone}
                </a>
              </div>
            </div>
          </div>

          <div className="footer-section">
            <div className="logos">
              <Link to="/" className="logo">
                <img src={Logo} alt="Connect logo" />
              </Link>
              <div className="social">
                <a href="https://www.facebook.com/ConnectCayman/" target="_blank">
                  <img src={Fb} alt="Connect facebook" />
                </a>
                <a href="https://twitter.com/ConnectCayman" target="_blank">
                  <img src={Tw} alt="Connect twitter" />
                </a>
                <a href="https://www.instagram.com/connectcayman1/" target="_blank">
                  <img src={Ig} alt="Connect instagram" />
                </a>
                <a href="https://linkedin.com/company/connectky" target="_blank">
                  <img src={Li} alt="Connect linkedIn" />
                </a>
              </div>
            </div>

            <div className="contact">
              <p>{contact.address}</p>
              <a href={`mailto:${contact.email}`} className="email underline">
                {contact.email}
              </a>
              <a href={`mailto:${contact.email2}`} className="email underline">
                {contact.email2}
              </a>
              <a href={`tel:${contact.phone}`} className="underline tel">
                {fullPhone}
              </a>
            </div>

            <div className="links">
              <ul className="link-group">
                <li>
                  <Link to="/services" className="navlink">
                    Services
                  </Link>
                </li>
                {sectors.map((sector, i) => (
                  <li key={i}>
                    <Link to={`/${sector.slug.current}`} className="navlink">
                      {sector.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <ul className="link-group">
                <li>
                  <Link to="/about" className="navlink">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="navlink">
                    Contact us
                  </Link>
                </li>
                {/* <li>
                  <Link to="/" className="navlink">
                    Terms &amp; conditions
                  </Link>
                </li>
                <li>
                  <Link to="/" className="navlink">
                    Privacy policy
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="copyright-section">
            <p>©{new Date().getFullYear()} Connect LTD. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
