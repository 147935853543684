import React, { useEffect } from 'react';

import '../css/main.scss';
import Navbar from './Navbar';
import ContactModal from './ContactModal';
import Footer from './Footer';

import { contactModalHandler } from '../utils/helpers';

export default function Layout({ children, transparentNav }) {
  useEffect(() => {
    contactModalHandler();
  });

  return (
    <>
      <ContactModal />
      <Navbar transparentBg={transparentNav} />
      {children}
      <Footer />
    </>
  );
}
