/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { ValidationError, useForm } from '@formspree/react';
import '../css/_contact-form.scss';

export default function ContactForm({ heading, p }) {
  const formRef = useRef(null);
  // const toastRef = useRef(null);
  const [state, handleSubmit] = useForm('xrgrqjwr', {
    data: {
      _subject: 'New Consultation Enquiry from Website',
    },
  });

  if (state.succeeded) {
    // formRef.reset();
    // const toastRef = document.querySelector('.toaster');
    // toastRef.classList.add('show');
    // setTimeout(function () {
    //   toastRef.classList.remove('show');
    // }, 3000);
    return (
      <div className="contact-form submitted">
        <h4>Your Form Has Been Submitted</h4>
        <p>Thank you. We will contact you shortly.</p>
      </div>
    );
  }

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit} ref={formRef}>
        <div className="heading">
          {heading && <h4>{heading}</h4>}
          {p && <p>{p}</p>}
        </div>

        <div className="form-elems">
          <div className="form-group">
            <label htmlFor="name">Full name</label>
            <input type="text" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" />
            <ValidationError
              field="email"
              prefix="Email"
              errors={state.errors}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tel">Phone number</label>
            <input type="tel" name="phone" />
          </div>
          <div className="form-group">
            <label htmlFor="topic">Areas of interest</label>
            <select name="topic" id="topics">
              <option value="home-automation">Home Automation</option>
              <option value="outdoor">Outdoor</option>
              <option value="smart-business-solutions">
                Smart Business Solutions
              </option>
              <option value="hospitality">Hospitality</option>
            </select>
          </div>
          <div className="form-group message">
            <label htmlFor="message">
              Message <span>optional</span>
            </label>
            <textarea name="message" id="message" />
          </div>
          <div className="btn-container">
            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </div>
        </div>
      </form>

      {/* <form
        className="contact-form"
        action="https://formspree.io/f/mbjqowjn"
        method="POST"
      >
        <div className="heading">
          {heading && <h4>{heading}</h4>}
          {p && <p>{p}</p>}
        </div>

        <input type="hidden" name="_next" value="https://connectcayman.com/" />
        <input
          type="hidden"
          name="_subject"
          value="New Consultation Enquiry from Website"
        />

        <div className="form-elems">
          <div className="form-group">
            <label htmlFor="name">Full name</label>
            <input type="text" name="name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone number</label>
            <input type="phone" name="phone" />
          </div>
          <div className="form-group">
            <label htmlFor="topic">Reason Consultation</label>
            <select name="topic" id="topics">
              <option value="home-automation">Smart Home Automation</option>
              <option value="office-automation">Smart Office Automation</option>
            </select>
          </div>
          <div className="form-group message">
            <label htmlFor="message">
              Message <span>optional</span>
            </label>
            <textarea name="message" id="message" />
          </div>
          <div className="btn-container">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form> */}
    </>
  );
}
