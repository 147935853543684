import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import '../css/_hero.scss';

export default function Hero({ type, heroImg, heroImgAlt, h5, h1, p }) {
  return (
    <>
      <div className={`hero ${type ? `${type}` : ''}`}>
        <div className="wrapper">
          {type && (
            <GatsbyImage
              image={heroImg}
              loading="eager"
              alt={heroImgAlt}
              className="hero-bg"
            />
          )}
          <div className="content">
            {h5 && <h5>{h5}</h5>}
            <h1>{h1}</h1>
            {p && <p>{p}</p>}
          </div>
        </div>
      </div>
    </>
  );
}
