// Scroll navigation
export function scrollNav() {
  const docElement = document.documentElement;
  let didScroll = false;
  const navbar = document.querySelector('.navbar');

  function scrollPage() {
    const yaxis = window.pageYOffset || docElement.scrollTop;
    if (yaxis >= 1) {
      // 1px offset
      navbar.classList.add('scrolled');
    } else {
      navbar.classList.remove('scrolled');
    }
    didScroll = false;
  }

  window.addEventListener('scroll', function () {
    if (!didScroll) {
      didScroll = true;
      setTimeout(scrollPage, 50);
    }
  });
}

// Don't scroll when popup is opened
export function scrollDisable() {
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  const bodyElem = document.body;
  bodyElem.style.position = 'fixed';
  bodyElem.style.top = `-${scrollY}`;
  document.documentElement.style.scrollBehavior = 'smooth';
}
export function scrollEnable() {
  const bodyElem = document.body;
  const scrollY = bodyElem.style.top;
  bodyElem.style.position = '';
  bodyElem.style.top = '';
  document.documentElement.style.scrollBehavior = 'initial';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

// Contact modal
export function contactModalHandler() {
  const contactModal = document.querySelector('.contact-modal');
  const close = document.querySelector('.contact-modal .close');
  const openModalBtn = document.querySelectorAll('.open-contact-modal');

  openModalBtn.forEach((btn) => {
    btn.addEventListener('click', function () {
      // scrollDisable();
      contactModal.classList.add('show');
    });
  });

  close.addEventListener('click', function () {
    // scrollEnable();
    contactModal.classList.remove('show');
  });

  // window.addEventListener('click', function (e) {
  //   if (e.target === contactModal) {
  //     // scrollEnable();
  //     contactModal.classList.remove('show');
  //   }
  // });
}
